<script lang="ts" setup>
import type { NotificationMessage, NotificationMessagePayload } from "@/types";

const MAX_TITLE_LENGTH = 32;
const MAX_MESSAGE_LENGTH = 64;

const props = defineProps<{
	data: NotificationMessage;
}>();

const emit = defineEmits<{
	(event: "clickMessage", payload: NotificationMessagePayload): void;
	(event: "closePopup"): void;
}>();

const { t } = useT();
const { durationLeft, duration } = useCountdown({ timestamp: props.data.dateEnd, formatToken: "H:m:ss" });
const [isShowAll, toggleShowAll] = useToggle(false);

const isShowTimer = computed(() => {
	if (props.data.type !== "offer") {
		return false;
	}

	const isStartLessNow = new Date(props.data.dateBegin).getTime() <= new Date().getTime();
	const isEndMoreNow = new Date(props.data.dateEnd).getTime() > new Date().getTime();

	return isStartLessNow && isEndMoreNow;
});
const isLongMessage = computed(
	() => props.data.message?.length > MAX_MESSAGE_LENGTH || props.data.title?.length > MAX_TITLE_LENGTH
);

const handleClickOnMessage = ({
	needSendStatusToServer = false,
	shouldOpenLink = false
}: Partial<Omit<NotificationMessagePayload, "id">> = {}) => {
	if (isLongMessage.value && !isShowAll.value) {
		toggleShowAll();
		return;
	}

	dispatchGAEvent({
		event: "click_button",
		location: props.data.isReaded ? "earlier" : "new",
		button_name: "notification",
		type: props.data.systemAlias
	});

	emit("clickMessage", { id: props.data.ncMessageId, needSendStatusToServer, shouldOpenLink });
	if (shouldOpenLink) {
		emit("closePopup");
	}
};

watch(duration, () => {
	if (duration.value < 1) {
		handleClickOnMessage();
	}
});
</script>
<template>
	<div
		class="notification-item"
		:class="{ 'is-read': data.isReaded, 'is-long-text': isLongMessage && !isShowAll }"
		@click.stop="handleClickOnMessage({ needSendStatusToServer: true, shouldOpenLink: true })"
	>
		<span v-if="!data.isReaded" class="unread-mark" />
		<img :src="data.image?.replace('http:', 'https:')" :alt="data.title" width="40" height="40" />
		<div class="content">
			<AText class="title" type="small" :modifiers="['bold']">{{ data.title }}</AText>
			<AText class="message" type="x-small">{{ data.message }}</AText>
			<AText
				v-if="isLongMessage"
				type="x-small"
				:modifiers="['link', 'left', 'underline']"
				@click.stop="toggleShowAll()"
			>
				{{ isShowAll ? t("Less details") : t("More details") }}
			</AText>
		</div>
		<div class="action">
			<AButton v-if="data.link" variant="primary" size="s">
				{{ data.buttonLabel || t("More") }}
			</AButton>
			<AText v-if="isShowTimer && duration" class="timer" type="xx-small">
				<i18n-t keypath="{key} left">
					<template #key>
						{{ durationLeft }}
					</template>
				</i18n-t>
			</AText>
		</div>
	</div>
</template>
<style scoped lang="scss">
.notification-item {
	padding: 8px 16px;
	display: flex;
	align-items: center;
	gap: 8px;
	border-bottom: 1px solid var(--gray-700);
	cursor: pointer;

	&.is-read {
		cursor: auto;

		.title {
			color: var(--gray-200);
		}
	}

	&.is-long-text {
		.title,
		.message {
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.unread-mark {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: var(--danger-300);
	}

	img {
		flex-shrink: 0;
	}

	button {
		padding: gutter(0.625) gutter(1.25);
		min-width: 64px;
	}

	.content {
		display: flex;
		flex-direction: column;
		gap: 3px;
		flex: 1;
		color: var(--gray-50);
	}

	.message {
		color: var(--gray-200);
	}

	.action {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		gap: 4px;

		.timer {
			color: var(--danger-300);
		}
	}
}
</style>
